// apply base url for api's
/**
 * local url: http://localhost:3000/v1
 * dev url: https://dev.hevara.africa/v1
 * qa url: https://qa.hevara.africa/v1
 * demo url: https://demo.hevara.africa/v1
 * prod url: https://api.hevara.africa/v1
 */

let baseUrl = "https://qa.hevara.africa/v1"

export default {
  getApi() {
    return {
      AUTH: {
        LOGIN: `${baseUrl}/auth/login`,
        LOGOUT: `${baseUrl}/auth/logout`,
        FORGOT_PASSWORD: `${baseUrl}/auth/forgot-password`,
        RECOVER_PASSWORD: `${baseUrl}/auth/reset-password`,
        REFRESH_TOKEN: `${baseUrl}/auth/refresh-tokens`,
        REQUEST_OTP_CODE: `${baseUrl}/auth/request-otp`,
        ACCOUNT_SETUP: `${baseUrl}/auth/account-setup`,
        CHECK_ONBOARDING_TOKEN: `${baseUrl}/auth/verify-onboarding-token`,
        RESEND_ONBOARDING_EMAIL: `${baseUrl}/auth/resend-onboarding-email`,
        CHANGE_PASSWORD: `${baseUrl}/auth/change-password`,
      },
      ONBOARDING: {
        REGISTER: `${baseUrl}/auth/register`,
      },
      COMPANIES: {
        ADD_COMPANY: `${baseUrl}/companies/addCompany`,
        GET_COMPANY: `${baseUrl}/companies/getCompany`,
        UPDATE_COMPANY: `${baseUrl}/companies/updateCompany`,
      },
      COMPANY_ROLES: {
        ADD_COMPANY_ROLE: `${baseUrl}/companyRoles/addCompanyRole`,
        GET_COMPANY_ROLES: `${baseUrl}/companyRoles/getCompanyRoles`,
        GET_COMPANY_ROLE: `${baseUrl}/companyRoles/getCompanyRole`,
        UPDATE_COMPANY_ROLE: `${baseUrl}/companyRoles/updateCompanyRole`,
      },
      PAYMENT_CONFIGS: {
        ADD_PAYMENT_CONFIG: `${baseUrl}/paymentConfigs/addPaymentConfig`,
        GET_PAYMENT_CONFIGS: `${baseUrl}/paymentConfigs/getPaymentConfigs`,
        GET_PAYMENT_CONFIG: `${baseUrl}/paymentConfigs/getPaymentConfig`,
        UPDATE_PAYMENT_CONFIG: `${baseUrl}/paymentConfigs/updatePaymentConfig`,
      },
      WAREHOUSES: {
        ADD_WAREHOUSE: `${baseUrl}/warehouses/addWarehouse`,
        GET_WAREHOUSES: `${baseUrl}/warehouses/getWarehouses`,
        GET_WAREHOUSE: `${baseUrl}/warehouses/getWarehouse`,
        UPDATE_WAREHOUSE: `${baseUrl}/warehouses/updateWarehouse`,
      },
      USERS: {
        ADD_USER: `${baseUrl}/users`,
        GET_USERS: `${baseUrl}/users`,
        GET_USER_DETAILS: `${baseUrl}/users`,
        UPDATE_USER: `${baseUrl}/users`,
        DELETE_USER: `${baseUrl}/users`,
      },
      FLEETS: {
        ADD_FLEET: `${baseUrl}/vehicles/addVehicle`,
        GET_FLEETS: `${baseUrl}/vehicles/getVehicles`,
        GET_FLEET: `${baseUrl}/vehicles/getVehicle`,
        UPDATE_FLEET: `${baseUrl}/vehicles/updateVehicle`,
      },
      PRODUCT: {
        CREATE_PRODUCT: `${baseUrl}/products/addProduct`,
        GET_PRODUCTS: `${baseUrl}/products/getProducts`,
        GET_PRODUCT: `${baseUrl}/products/getProduct`,
        UPDATE_PRODUCT: `${baseUrl}/products/updateProduct`,
        DELETE_PRODUCT: `${baseUrl}/products/deleteProduct`,
      },
      PRODUCT_CATALOGUE: {
        CREATE_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/createProductCatalogue`,
        ADD_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/addProductCatalogue`,
        GET_PRODUCT_CATALOGUES: `${baseUrl}/productCatalogue/getProductCatalogues`,
        GET_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/getProductCatalogue`,
        UPDATE_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/updateProductCatalogue`,
        DELETE_PRODUCT_CATALOGUE: `${baseUrl}/products/deleteProductCatalogue`,
      },
      ORDER: {
        CREATE_ORDER: `${baseUrl}/orders/addOrder`,
        GET_ORDERS: `${baseUrl}/orders/getOrders`,
        GET_ORDER: `${baseUrl}/orders/getOrder`,
        UPDATE_ORDER: `${baseUrl}/orders/updateOrder`,
        DELETE_ORDER: `${baseUrl}/orders/deleteOrder`,
      },
      SUB_ORDERS: {
        GET_SUB_ORDERS: `${baseUrl}/suborders/getSubOrders`,
        GET_SUB_ORDER: `${baseUrl}/suborders/getSubOrder`,
        UPDATE_SUB_ORDER: `${baseUrl}/suborders/updateSubOrder`,
        DELETE_SUB_ORDER: `${baseUrl}/suborders/deleteSuborder`,
      },
      SUB_ORDER_PAYMENTS: {
        INITIATE_SUB_ORDER_PAYMENT: `${baseUrl}/subOrderPayments/initiateSubOrderPayment`,
      },
      CUSTOMERS: {
        ADD_CUSTOMER: `${baseUrl}/customers/addCustomer`,
        GET_CUSTOMERS: `${baseUrl}/customers/getCustomers`,
        GET_CUSTOMER: `${baseUrl}/customers/getCustomer`,
        UPDATE_CUSTOMER: `${baseUrl}/customers/updateCustomer`,
      },
      UPLOADS: {
        ADD_UPLOAD: `${baseUrl}/uploads/addUpload`,
        GET_UPLOADS: `${baseUrl}/uploads/getUploads`,
        GET_UPLOAD: `${baseUrl}/uploads/getUpload`,
        UPDATE_UPLOAD: `${baseUrl}/uploads/updateUpload`,
        DELETE_UPLOAD: `${baseUrl}/uploads/deleteUpload`,
      },
      PRODUCT_MASTER_LIST: {
        GET_PRODUCT_LISTS: `${baseUrl}/productList/getProductLists`,
        GET_PRODUCT_LIST: `${baseUrl}/productList/getProductList`,
      },
      PRODUCTS_VIEW: {
        PRODUCTS_VIEW: `${baseUrl}/productsView/productsView`,
      },
      NOTIFICATION_PREFERENCE: {
        ADD_PREFERENCE: `${baseUrl}/notificationPreferences/addNotificationPreference`,
        GET_PREFERENCES: `${baseUrl}/notificationPreferences/getNotificationPreferences`,
        UPDATE_PREFERENCE: `${baseUrl}/notificationPreferences/updateNotificationPreference`,
      },
      GENERATE_PDF: {
        GET_SUBORDER_PDF: `${baseUrl}/pdfs/getSubOrderPDF`,
        GET_BILLING_PAYMENT_PDF: `${baseUrl}/pdfs/getBillingPaymentPDF`,
      },
      TAX: {
        ADD_TAX: `${baseUrl}/taxes/addTax`,
        GET_TAXES: `${baseUrl}/taxes/getTaxes`,
        GET_TAX: `${baseUrl}/taxes/getTax`,
        UPDATE_TAX: `${baseUrl}/taxes/updateTax`,
      },
      CHARGE: {
        ADD_CHARGE: `${baseUrl}/charges/addCharge`,
        GET_CHARGES: `${baseUrl}/charges/getCharges`,
        GET_CHARGE: `${baseUrl}/charges/getCharge`,
        UPDATE_CHARGE: `${baseUrl}/charges/updateCharge`,
      },
      WALLETS: {
        CREATE_WALLET: `${baseUrl}/wallets/createWallet`,
        GET_WALLETS: `${baseUrl}/wallets/getWallets`,
        GET_WALLET: `${baseUrl}/wallets/getWallet`,
        UPDATE_WALLET: `${baseUrl}/wallets/updateWallet`,
        DELETE_WALLET: `${baseUrl}/wallets/deleteWallet`,
      },
      WALLET_TOP_UP: {
        TOP_UP: `${baseUrl}/walletTopUp/top-up`,
        GET_TOP_UPS: `${baseUrl}/walletTopUp/get-top-ups`,
        GET_TOP_UP: `${baseUrl}/walletTopUp/get-top-up`,
      },
      TRANSFERS: {
        TRANSFER: `${baseUrl}/wallet-transfer/transfer`,
        GET_TRANSFERS: `${baseUrl}/wallet-transfer/get-transfers`,
        GET_TRANSFER: `${baseUrl}/wallet-transfer/get-transfer`,
      },
      BILLING_PLANS: {
        SELECT_BILLING_PLAN: `${baseUrl}/billing_plans/selectBillingPlan`,
      },
      BILLING_PAYMENT: {
        INITIATE_PAYMENT: `${baseUrl}/billingPayments/initiateBillingPayment`,
        VERIFY_PAYMENT: `${baseUrl}/billingPayments/verifyBillingPayment`,
        GET_BILLING_PAYMENTS: `${baseUrl}/billingPayments/getBillingPayments`,
        GET_BILLING_PAYMENT: `${baseUrl}/billingPayments/getBillingPayment`,
      },
      SHIPMENT: {
        CREATE_SHIPMENT: `${baseUrl}/shipments/createShipment`,
        ADD_SHIPMENT_PRODUCT: `${baseUrl}/shipments/addShipmentProduct`,
        GET_SHIPMENTS: `${baseUrl}/shipments/getShipments`,
        GET_SHIPMENT: `${baseUrl}/shipments/getShipment`,
        UPDATE_SHIPMENT: `${baseUrl}/shipments/updateShipment`,
        ARCHIVE_SHIPMENT: `${baseUrl}/shipments/archiveShipment`,
        DELETE_SHIPMENT: `${baseUrl}/shipments/deleteShipment`,
      },
      CART: {
        ADD_TO_CART: `${baseUrl}/cart/addCart`,
        GET_CART: `${baseUrl}/cart/getCarts`,
        UPDATE_CART: `${baseUrl}/cart/updateCart`,
      },
      LOOKUPS: {
        COMPANY_TYPES: `${baseUrl}/lookUps/getCompanyTypes`,
        COUNTRIES: `${baseUrl}/lookUps/getCountries`,
        ROLES: `${baseUrl}/lookUps/getRoles`,
        PAYMENT_METHODS: `${baseUrl}/lookUps/getPaymentMethods`,
        VEHICLE_TYPES: `${baseUrl}/lookUps/getVehicleTypes`,
        COMPANY_WAREHOUSES: `${baseUrl}/lookUps/getCompanyWarehouses`,
        COMPANY_ROLES: `${baseUrl}/lookUps/getCompanyRoles`,
        PRODUCT_TYPES: `${baseUrl}/lookUps/getProductTypes`,
        PRODUCT_CATEGORIES: `${baseUrl}/lookUps/getProductCategories`,
        MANUFACTURERS: `${baseUrl}/lookUps/getAllManufacturers`,
        DISTRIBUTORS: `${baseUrl}/lookUps/getAllDistributors`,
        CUSTOMERS: `${baseUrl}/lookUps/getCompanyCustomers`,
        ORDER_STATUS: `${baseUrl}/lookUps/getOrderStatuses`,
        CURRENCIES: `${baseUrl}/lookUps/getCurrencies`,
        BILLING_PLANS: `${baseUrl}/lookUps/getBillingPlans`,
        PRODUCT_LIST: `${baseUrl}/lookUps/getProductList`,
      },
    }
  },
}
